//  =================
//      Imports
//  =================

@import '../../base/base';    // Base Variables

/*
==================
    Switches
==================
*/

/* The switch - the box around the slider */

.switch {
  position: relative;
  display: inline-block;
  width: 35px;
  height: 18px;

  input {
    display: none;
  }

  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: $m-color_2;
    -webkit-transition: .4s;
    transition: .4s;

    &:before {
      position: absolute;
      content: "";
      background-color: white;
      -webkit-transition: .4s;
      -ms-transition: .4s;
      transition: .4s;
      height: 14px;
      width: 14px;
      left: 2px;
      bottom: 2px;
      box-shadow: 0 1px 15px 1px rgba(52, 40, 104, 0.34);
    }
  }

  input:checked + .slider:before {
    -webkit-transform: translateX(17px);
    -ms-transform: translateX(17px);
    transform: translateX(17px);
  }

  .slider.round {
    border-radius: 34px;

    &:before {
      border-radius: 50%;
    }
  }

  &.s-default .slider:before {
    background-color: $m-color_5;
  }

  &.s-primary .slider:before {
    background-color: $primary;
  }

  &.s-success .slider:before {
    background-color: $success;
  }

  &.s-warning .slider:before {
    background-color: $warning;
  }

  &.s-danger .slider:before {
    background-color: $danger;
  }

  &.s-secondary .slider:before {
    background-color: $secondary;
  }

  &.s-info .slider:before {
    background-color: $info;
  }

  &.s-dark .slider:before {
    background-color: $dark;
  }

  input:checked + .slider:before {
    background-color: $white;
  }

  &.s-default input {
    &:checked + .slider {
      background-color: $m-color_5;
    }

    &:focus + .slider {
      box-shadow: 0 0 1px $m-color_2;
    }
  }

  &.s-primary input {
    &:checked + .slider {
      background-color: $primary;
    }

    &:focus + .slider {
      box-shadow: 0 0 1px $primary;
    }
  }

  &.s-success input {
    &:checked + .slider {
      background-color: $success;
    }

    &:focus + .slider {
      box-shadow: 0 0 1px $success;
    }
  }

  &.s-warning input {
    &:checked + .slider {
      background-color: $warning;
    }

    &:focus + .slider {
      box-shadow: 0 0 1px $warning;
    }
  }

  &.s-danger input {
    &:checked + .slider {
      background-color: $danger;
    }

    &:focus + .slider {
      box-shadow: 0 0 1px $danger;
    }
  }

  &.s-secondary input {
    &:checked + .slider {
      background-color: $secondary;
    }

    &:focus + .slider {
      box-shadow: 0 0 1px $secondary;
    }
  }

  &.s-info input {
    &:checked + .slider {
      background-color: $info;
    }

    &:focus + .slider {
      box-shadow: 0 0 1px $info;
    }
  }

  &.s-dark input {
    &:checked + .slider {
      background-color: $dark;
    }

    &:focus + .slider {
      box-shadow: 0 0 1px $dark;
    }
  }

  &.s-outline {
    .slider {
      border: 2px solid $m-color_2;
      background-color: transparent;
      width: 36px;
      height: 19px;

      &:before {
        height: 13px;
        width: 13px;
      }
    }

    &[class*="s-outline-"] .slider:before {
      bottom: 1px;
      left: 1px;
      border: 2px solid $m-color_4;
      background-color: $m-color_4;
      color: $m-color_2;
      box-shadow: 0 1px 15px 1px rgba(52, 40, 104, 0.25);
    }
  }

  &.s-icons {
    &.s-outline-default {
      color: $m-color_5;
    }

    &.s-outline-primary {
      color: $primary;
    }

    &.s-outline-success {
      color: $success;
    }

    &.s-outline-warning {
      color: $warning;
    }

    &.s-outline-danger {
      color: $danger;
    }

    &.s-outline-secondary {
      color: $secondary;
    }

    &.s-outline-info {
      color: $info;
    }

    &.s-outline-dark {
      color: $dark;
    }
  }

  &.s-outline-default input {
    &:checked + .slider {
      border: 2px solid $m-color_2;

      &:before {
        border: 2px solid $m-color_5;
        background-color: $m-color_5;
        box-shadow: 0 1px 15px 1px rgba(52, 40, 104, 0.25);
      }
    }

    &:focus + .slider {
      box-shadow: 0 0 1px $m-color_2;
    }
  }

  &.s-outline-primary input {
    &:checked + .slider {
      border: 2px solid $primary;

      &:before {
        border: 2px solid $primary;
        background-color: $primary;
        box-shadow: 0 1px 15px 1px rgba(52, 40, 104, 0.34);
      }
    }

    &:focus + .slider {
      box-shadow: 0 0 1px $primary;
    }
  }

  &.s-outline-success input {
    &:checked + .slider {
      border: 2px solid $success;

      &:before {
        border: 2px solid $success;
        background-color: $success;
        box-shadow: 0 1px 15px 1px rgba(52, 40, 104, 0.34);
      }
    }

    &:focus + .slider {
      box-shadow: 0 0 1px $success;
    }
  }

  &.s-outline-warning input {
    &:checked + .slider {
      border: 2px solid $warning;

      &:before {
        border: 2px solid $warning;
        background-color: $warning;
        box-shadow: 0 1px 15px 1px rgba(52, 40, 104, 0.34);
      }
    }

    &:focus + .slider {
      box-shadow: 0 0 1px $warning;
    }
  }

  &.s-outline-danger input {
    &:checked + .slider {
      border: 2px solid $danger;

      &:before {
        border: 2px solid $danger;
        background-color: $danger;
        box-shadow: 0 1px 15px 1px rgba(52, 40, 104, 0.34);
      }
    }

    &:focus + .slider {
      box-shadow: 0 0 1px $danger;
    }
  }

  &.s-outline-secondary input {
    &:checked + .slider {
      border: 2px solid $secondary;

      &:before {
        border: 2px solid $secondary;
        background-color: $secondary;
        box-shadow: 0 1px 15px 1px rgba(52, 40, 104, 0.34);
      }
    }

    &:focus + .slider {
      box-shadow: 0 0 1px $secondary;
    }
  }

  &.s-outline-info input {
    &:checked + .slider {
      border: 2px solid $info;

      &:before {
        border: 2px solid $info;
        background-color: $info;
        box-shadow: 0 1px 15px 1px rgba(52, 40, 104, 0.34);
      }
    }

    &:focus + .slider {
      box-shadow: 0 0 1px $info;
    }
  }

  &.s-outline-dark input {
    &:checked + .slider {
      border: 2px solid $dark;

      &:before {
        border: 2px solid $dark;
        background-color: $dark;
        box-shadow: 0 1px 15px 1px rgba(52, 40, 104, 0.34);
      }
    }

    &:focus + .slider {
      box-shadow: 0 0 1px $dark;
    }
  }

  &.s-icons {
    width: 57px;
    height: 30px;

    .slider {
      width: 48px;
      height: 25px;

      &:before {
        vertical-align: sub;
        color: $dark;
        height: 19px;
        width: 19px;
        line-height: 1.3;
        content: url('data:image/svg+xml, <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 24 24" fill="none" stroke="%23e9ecef" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-x"><line x1="18" y1="6" x2="6" y2="18"></line><line x1="6" y1="6" x2="18" y2="18"></line></svg>');
      }
    }

    input:checked + .slider:before {
      content: url('data:image/svg+xml, <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 24 24" fill="none" stroke="%23fff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-check"><polyline points="20 6 9 17 4 12"></polyline></svg>');
      vertical-align: sub;
      color: $white;
      line-height: 1.4;
      -webkit-transform: translateX(23px);
      -ms-transform: translateX(23px);
      transform: translateX(23px);
    }
  }
}

/* Hide default HTML checkbox */

/* The slider */

/* Rounded Slider Switches */

/* Solid Switches*/

/* Outline Switches */

/*  Icons Switches */